/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, SeparateLine, SeparateLineWrap, Image, Subtitle, Title, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"víno Carlos - Salento Rosato I.G.P."}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column name={"[[UNIsectionname12]]"} style={{"paddingBottom":0,"paddingTop":152}} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingBottom":0,"paddingTop":0,"marginTop":32}} name={"g36nbh5sc7c"}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"marginTop":0}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--left" style={{"paddingBottom":0}} content={"<a href=\"/carlos\">&lt; Carlos </a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10" style={{"marginTop":0,"paddingTop":0}} name={"rzzksog7hkn"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"sdh6u0twq"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" style={{"maxWidth":1607,"paddingRight":0}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":319}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/40863/9a3997becc624f8b921ca6e7aa8d8b48_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/40863/9a3997becc624f8b921ca6e7aa8d8b48_s=350x_.png"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: var(--color-custom-2);\">Carlos</span><br>"}>
              </Subtitle>

              <Title className="title-box lh--12" content={"Salento Rosato I.G.P.\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":767}} content={"Víno Negroamaro a další místní odrůdy ze Salenta\nVíno vyrobené z odrůd Negroamaro a autochtonních hroznů salentského regionu, které zrálo po dobu 3 měsíců v nerezových tancích, aby vynikly jeho vůně a aromata.\n<br><br>&nbsp;Má růžovou barvu s odstíny třešňové červeně a korálovými odlesky. Vůně je jemná, s ovocnými tóny. <br><br>Chuť je suchá, harmonická, s příjemným lehce hořkým zakončením.\n\nSkvěle se hodí k rybím pokrmům, pečeným rybám a bílým masům. Ideální volba k lehkým a rafinovaným jídlům."}>
              </Text>

              <Button className="btn-box" content={"objednat"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"ljodpetnirn"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-blend--50);\">Děkujeme, že jste si vybrali právě nás.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box ff--1" content={"<span style=\"color: var(--color-dominant);\">The Wine<br><span style=\"font-style: italic;\">of the</span> Champions<br></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-blend--50);\">+420 797 811 2X0<br>info@vinalegend.com</span>"}>
              </Text>

              <Image style={{"maxWidth":24,"marginTop":13}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""} alt={"instagram"} src={"https://cdn.swbpg.com/o/40863/b29c23bfb6ac42fbb4d2db5a1aebd354.png"} lightbox={false} use={"url"} href={"https://www.instagram.com/jankul18/"} url={"https://www.instagram.com/jankul18/"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}